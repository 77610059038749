// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-news-description {
  font-size: 22px;
  font-family: LeagueSpartan-Regular;
  text-align: justify;
}

.carousel-news-title {
  font-size: 22px;
  text-align: justify;
  text-transform: uppercase;
  text-align: start;
}

.carousel-news-caption {
  margin-top: 5%;
}

.container-carousel-news {
  width: 100%;
  height: 500px;
  position: relative;
}

.container-carousel-news .carousel-indicators {
  display: none;
}

.empty-carousel-image {
  width: 100%;
  height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/News/CarouselNews.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kCAAkC;EAClC,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".carousel-news-description {\n  font-size: 22px;\n  font-family: LeagueSpartan-Regular;\n  text-align: justify;\n}\n\n.carousel-news-title {\n  font-size: 22px;\n  text-align: justify;\n  text-transform: uppercase;\n  text-align: start;\n}\n\n.carousel-news-caption {\n  margin-top: 5%;\n}\n\n.container-carousel-news {\n  width: 100%;\n  height: 500px;\n  position: relative;\n}\n\n.container-carousel-news .carousel-indicators {\n  display: none;\n}\n\n.empty-carousel-image {\n  width: 100%;\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
