// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-news-container {
  padding: 10%;
}

@media (min-width: 768px) {
  input[type="text"],
  input[type="date"],
  textarea {
    font-size: 1.2rem;
    padding: 0.5rem;
    width: 100%;
    max-width: 500px;
  }
}

@media (max-width: 768px) {
  .add-news-container {
    padding: 1rem;
    padding-top: 20%;
  }
  
  input[type="file"] {
    margin-bottom: 1rem;
  }
  
  button.submit {
    margin-top: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PanelAdmin/Options/OurClub/News/AddNews/AddNews.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE;;;IAGE,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".add-news-container {\n  padding: 10%;\n}\n\n@media (min-width: 768px) {\n  input[type=\"text\"],\n  input[type=\"date\"],\n  textarea {\n    font-size: 1.2rem;\n    padding: 0.5rem;\n    width: 100%;\n    max-width: 500px;\n  }\n}\n\n@media (max-width: 768px) {\n  .add-news-container {\n    padding: 1rem;\n    padding-top: 20%;\n  }\n  \n  input[type=\"file\"] {\n    margin-bottom: 1rem;\n  }\n  \n  button.submit {\n    margin-top: 1rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
