// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.events-container-component {
  width: 100%;
  height: 100%;
}

.en-construccion {
  text-align: center;
  padding-top: 15%;
  font-size: 75px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Events/Events.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".events-container-component {\n  width: 100%;\n  height: 100%;\n}\n\n.en-construccion {\n  text-align: center;\n  padding-top: 15%;\n  font-size: 75px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
