// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10%;
}

.panel-container ul {
  display: grid;
  justify-items: center;
}

.links-options {
  text-decoration: none;
  color: white;
}

.links-options:hover {
  color: white;
  cursor: pointer;
}

.panel-container ul button {
  border: none;
  border: 3px solid blueviolet;
  background: black;
  padding: 10px;
  min-width: 500px;
  width: 100%;
  margin-top: 30px;
  color: white;
  font-size: 20px;
  border-radius: 4px;
  transition: ease-out 0.3s;
  z-index: 1;
  position: relative;
}

.panel-container ul button:before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  content: "";
  background-color: blueviolet;
  color: white;
  opacity: 0;
}

.panel-container ul button:hover:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
  z-index: -1;
}

@media (max-width: 575.98px) {
  .panel-container ul button {
    min-width: 200px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PanelAdmin/PanelAdmin.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,4BAA4B;EAC5B,iBAAiB;EACjB,aAAa;EACb,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,yBAAyB;EACzB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,UAAU;EACV,SAAS;EACT,WAAW;EACX,4BAA4B;EAC5B,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,OAAO;EACP,QAAQ;EACR,UAAU;EACV,WAAW;AACb;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".panel-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding-top: 10%;\n}\n\n.panel-container ul {\n  display: grid;\n  justify-items: center;\n}\n\n.links-options {\n  text-decoration: none;\n  color: white;\n}\n\n.links-options:hover {\n  color: white;\n  cursor: pointer;\n}\n\n.panel-container ul button {\n  border: none;\n  border: 3px solid blueviolet;\n  background: black;\n  padding: 10px;\n  min-width: 500px;\n  width: 100%;\n  margin-top: 30px;\n  color: white;\n  font-size: 20px;\n  border-radius: 4px;\n  transition: ease-out 0.3s;\n  z-index: 1;\n  position: relative;\n}\n\n.panel-container ul button:before {\n  transition: 0.5s all ease;\n  position: absolute;\n  top: 0;\n  left: 50%;\n  right: 50%;\n  bottom: 0;\n  content: \"\";\n  background-color: blueviolet;\n  color: white;\n  opacity: 0;\n}\n\n.panel-container ul button:hover:before {\n  transition: 0.5s all ease;\n  left: 0;\n  right: 0;\n  opacity: 1;\n  z-index: -1;\n}\n\n@media (max-width: 575.98px) {\n  .panel-container ul button {\n    min-width: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
