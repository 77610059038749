// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container-user h1 {
  font-size: 16px;
}

.card-container-user button {
  border: none;
  border: 3px solid blueviolet;
  background: black;
  padding: 10px;
  margin-top: 30px;
  color: white;
  font-size: 20px;
  border-radius: 4px;
  transition: ease-out 0.3s;
  z-index: 1;
  position: relative;
}

.card-container-user button:before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  content: "";
  background-color: blueviolet;
  color: white;
  opacity: 0;
}

.card-container-user button:hover:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
  z-index: -1;
}
`, "",{"version":3,"sources":["webpack://./src/components/PanelAdmin/Options/Users/DeleteUsers/DeleteUserCard.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,4BAA4B;EAC5B,iBAAiB;EACjB,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,yBAAyB;EACzB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,UAAU;EACV,SAAS;EACT,WAAW;EACX,4BAA4B;EAC5B,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,OAAO;EACP,QAAQ;EACR,UAAU;EACV,WAAW;AACb","sourcesContent":[".card-container-user h1 {\n  font-size: 16px;\n}\n\n.card-container-user button {\n  border: none;\n  border: 3px solid blueviolet;\n  background: black;\n  padding: 10px;\n  margin-top: 30px;\n  color: white;\n  font-size: 20px;\n  border-radius: 4px;\n  transition: ease-out 0.3s;\n  z-index: 1;\n  position: relative;\n}\n\n.card-container-user button:before {\n  transition: 0.5s all ease;\n  position: absolute;\n  top: 0;\n  left: 50%;\n  right: 50%;\n  bottom: 0;\n  content: \"\";\n  background-color: blueviolet;\n  color: white;\n  opacity: 0;\n}\n\n.card-container-user button:hover:before {\n  transition: 0.5s all ease;\n  left: 0;\n  right: 0;\n  opacity: 1;\n  z-index: -1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
