// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-title {
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 15%;
  font-size: 75px;
}

.not-found-number {
  color: #8342ea;
}

/* MEDIA QUERIES */

/* Notebook */
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .not-found-title {
    padding-top: 25%;
    font-size: 50px;
  }
}

/* Tablet acostada */
@media only screen and (min-width: 768.99px) and (max-width: 1023.99px) {
  .not-found-title {
    font-size: 40px;
    padding-top: 25%;
  }
}

/* Tablet */
@media only screen and (min-width: 480px) and (max-width: 768px) {
  .not-found-title {
    padding-top: 25%;
    font-size: 20px;
  }
}

/* Celu acostado */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .not-found-title {
    padding-top: 25%;
    font-size: 20px;
  }
}

/* Celu */
@media only screen and (max-width: 320px) {
  .not-found-title {
    padding-top: 25%;
    font-size: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/NotFound/NotFound.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA,kBAAkB;;AAElB,aAAa;AACb;EACE;IACE,gBAAgB;IAChB,eAAe;EACjB;AACF;;AAEA,oBAAoB;AACpB;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;AACF;;AAEA,WAAW;AACX;EACE;IACE,gBAAgB;IAChB,eAAe;EACjB;AACF;;AAEA,kBAAkB;AAClB;EACE;IACE,gBAAgB;IAChB,eAAe;EACjB;AACF;;AAEA,SAAS;AACT;EACE;IACE,gBAAgB;IAChB,eAAe;EACjB;AACF","sourcesContent":[".not-found-title {\n  height: 100%;\n  width: 100%;\n  text-align: center;\n  padding-top: 15%;\n  font-size: 75px;\n}\n\n.not-found-number {\n  color: #8342ea;\n}\n\n/* MEDIA QUERIES */\n\n/* Notebook */\n@media only screen and (min-width: 1024px) and (max-width: 1200px) {\n  .not-found-title {\n    padding-top: 25%;\n    font-size: 50px;\n  }\n}\n\n/* Tablet acostada */\n@media only screen and (min-width: 768.99px) and (max-width: 1023.99px) {\n  .not-found-title {\n    font-size: 40px;\n    padding-top: 25%;\n  }\n}\n\n/* Tablet */\n@media only screen and (min-width: 480px) and (max-width: 768px) {\n  .not-found-title {\n    padding-top: 25%;\n    font-size: 20px;\n  }\n}\n\n/* Celu acostado */\n@media only screen and (min-width: 320px) and (max-width: 480px) {\n  .not-found-title {\n    padding-top: 25%;\n    font-size: 20px;\n  }\n}\n\n/* Celu */\n@media only screen and (max-width: 320px) {\n  .not-found-title {\n    padding-top: 25%;\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
