// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-users-container {
  display: flex;
  justify-content: center;
  padding-top: 150px;
  height: 100%;
}

@media only screen and (min-width: 250px) and (max-width: 430px) {
  .add-users-container  {
    font-size: 14px;
    margin-left: 5%;
    margin-right: 5%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PanelAdmin/Options/Users/AddUsers/AddUsers.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE;IACE,eAAe;IACf,eAAe;IACf,gBAAgB;EAClB;AACF","sourcesContent":[".add-users-container {\n  display: flex;\n  justify-content: center;\n  padding-top: 150px;\n  height: 100%;\n}\n\n@media only screen and (min-width: 250px) and (max-width: 430px) {\n  .add-users-container  {\n    font-size: 14px;\n    margin-left: 5%;\n    margin-right: 5%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
