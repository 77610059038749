// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news-container {
  box-sizing: border-box;
  padding: 10% 6%;
  margin: 0 auto;
  max-width: 1000px;
  text-align: justify;
}

/* para pantallas de hasta 480px de ancho en modo apaisado (celulares) */
@media only screen and (max-width: 480px) and (orientation: landscape) {
  .news-container {
    padding: 10% 3%;
  }
}

/* para pantallas de hasta 768px de ancho (tablets y notebooks pequeñas) */
@media only screen and (max-width: 768px) {
  .news-container {
    padding: 10% 3%;
  }
}

/* para pantallas de hasta 1024px de ancho (notebooks y desktops medianas) */
@media only screen and (max-width: 1024px) {
  .news-container {
    padding: 20% 5%;
  }
}

.sitioEnConstruccion {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}
`, "",{"version":3,"sources":["webpack://./src/components/News/News.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA,wEAAwE;AACxE;EACE;IACE,eAAe;EACjB;AACF;;AAEA,0EAA0E;AAC1E;EACE;IACE,eAAe;EACjB;AACF;;AAEA,4EAA4E;AAC5E;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".news-container {\n  box-sizing: border-box;\n  padding: 10% 6%;\n  margin: 0 auto;\n  max-width: 1000px;\n  text-align: justify;\n}\n\n/* para pantallas de hasta 480px de ancho en modo apaisado (celulares) */\n@media only screen and (max-width: 480px) and (orientation: landscape) {\n  .news-container {\n    padding: 10% 3%;\n  }\n}\n\n/* para pantallas de hasta 768px de ancho (tablets y notebooks pequeñas) */\n@media only screen and (max-width: 768px) {\n  .news-container {\n    padding: 10% 3%;\n  }\n}\n\n/* para pantallas de hasta 1024px de ancho (notebooks y desktops medianas) */\n@media only screen and (max-width: 1024px) {\n  .news-container {\n    padding: 20% 5%;\n  }\n}\n\n.sitioEnConstruccion {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 10%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
