// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-container-news {
  padding: 10%
}

.edit-container-news button {
  border: none;
  border: 3px solid blueviolet;
  background: black;
  padding: 10px;
  width: 100%;
  margin-top: 30px;
  color: white;
  font-size: 20px;
  border-radius: 4px;
  transition: ease-out 0.3s;
  z-index: 1;
  position: relative;
}

.edit-container-news button:hover {
  color: white;
  cursor: pointer;
}

.edit-container-news button:before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  content: "";
  background-color: blueviolet;
  opacity: 0;
}

.edit-container-news button:hover:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
  z-index: -1;
}

.edit-container-news textarea {
  min-height: 10em;
  min-width: 100%;
}

@media only screen and (min-width: 250px) and (max-width: 430px) {
  .edit-container-news {
    font-size: 14px;
    margin-left: 5%;
    margin-right: 5%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PanelAdmin/Options/OurClub/News/EditViewNews/EditViewNews.css"],"names":[],"mappings":"AAAA;EACE;AACF;;AAEA;EACE,YAAY;EACZ,4BAA4B;EAC5B,iBAAiB;EACjB,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,yBAAyB;EACzB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,UAAU;EACV,SAAS;EACT,WAAW;EACX,4BAA4B;EAC5B,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,OAAO;EACP,QAAQ;EACR,UAAU;EACV,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE;IACE,eAAe;IACf,eAAe;IACf,gBAAgB;EAClB;AACF","sourcesContent":[".edit-container-news {\n  padding: 10%\n}\n\n.edit-container-news button {\n  border: none;\n  border: 3px solid blueviolet;\n  background: black;\n  padding: 10px;\n  width: 100%;\n  margin-top: 30px;\n  color: white;\n  font-size: 20px;\n  border-radius: 4px;\n  transition: ease-out 0.3s;\n  z-index: 1;\n  position: relative;\n}\n\n.edit-container-news button:hover {\n  color: white;\n  cursor: pointer;\n}\n\n.edit-container-news button:before {\n  transition: 0.5s all ease;\n  position: absolute;\n  top: 0;\n  left: 50%;\n  right: 50%;\n  bottom: 0;\n  content: \"\";\n  background-color: blueviolet;\n  opacity: 0;\n}\n\n.edit-container-news button:hover:before {\n  transition: 0.5s all ease;\n  left: 0;\n  right: 0;\n  opacity: 1;\n  z-index: -1;\n}\n\n.edit-container-news textarea {\n  min-height: 10em;\n  min-width: 100%;\n}\n\n@media only screen and (min-width: 250px) and (max-width: 430px) {\n  .edit-container-news {\n    font-size: 14px;\n    margin-left: 5%;\n    margin-right: 5%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
