// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-episode-container{
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.grid img{
    width: 300px;
    height: 300px;
    padding: 15px;
}

.title {
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/PanelAdmin/Options/OurClub/Episodes/UpdateEpisode/UpdateEpisode.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".update-episode-container{\n    padding-top: 150px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.grid{\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: center;\n}\n\n.grid img{\n    width: 300px;\n    height: 300px;\n    padding: 15px;\n}\n\n.title {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
