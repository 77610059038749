// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-episode-container {
    display: flex;
    justify-content: center;
    padding-top: 150px;
    height: 100%;
  }
  
  
  .submit {
    border: none;
    border: 3px solid blueviolet;
    background: black;
    padding: 10px;
    width: 100%;
    margin-top: 30px;
    color: white;
    font-size: 20px;
    border-radius: 4px;
    transition: ease-out 0.3s;
    z-index: 1;
    position: relative;
  }
  
  .submit:hover {
    color: white;
    cursor: pointer;
  }
  
  .submit:before {
    transition: 0.5s all ease;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    content: "";
    background-color: blueviolet;
    opacity: 0;
  }
  
  .submit:hover:before {
    transition: 0.5s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
  }
  
  .add-episode-container textarea {
    min-height: 10em;
    min-width: 100%;
  }
  


  @media only screen and (min-width: 250px) and (max-width: 430px) {
    .add-episode-container {
      font-size: 14px;
      margin-left: 5%;
      margin-right: 5%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/PanelAdmin/Options/OurClub/Episodes/AddEpisode/AddEpisode.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;EACd;;;EAGA;IACE,YAAY;IACZ,4BAA4B;IAC5B,iBAAiB;IACjB,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,yBAAyB;IACzB,UAAU;IACV,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,UAAU;IACV,SAAS;IACT,WAAW;IACX,4BAA4B;IAC5B,UAAU;EACZ;;EAEA;IACE,yBAAyB;IACzB,OAAO;IACP,QAAQ;IACR,UAAU;IACV,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,eAAe;EACjB;;;;EAIA;IACE;MACE,eAAe;MACf,eAAe;MACf,gBAAgB;IAClB;EACF","sourcesContent":[".add-episode-container {\n    display: flex;\n    justify-content: center;\n    padding-top: 150px;\n    height: 100%;\n  }\n  \n  \n  .submit {\n    border: none;\n    border: 3px solid blueviolet;\n    background: black;\n    padding: 10px;\n    width: 100%;\n    margin-top: 30px;\n    color: white;\n    font-size: 20px;\n    border-radius: 4px;\n    transition: ease-out 0.3s;\n    z-index: 1;\n    position: relative;\n  }\n  \n  .submit:hover {\n    color: white;\n    cursor: pointer;\n  }\n  \n  .submit:before {\n    transition: 0.5s all ease;\n    position: absolute;\n    top: 0;\n    left: 50%;\n    right: 50%;\n    bottom: 0;\n    content: \"\";\n    background-color: blueviolet;\n    opacity: 0;\n  }\n  \n  .submit:hover:before {\n    transition: 0.5s all ease;\n    left: 0;\n    right: 0;\n    opacity: 1;\n    z-index: -1;\n  }\n  \n  .add-episode-container textarea {\n    min-height: 10em;\n    min-width: 100%;\n  }\n  \n\n\n  @media only screen and (min-width: 250px) and (max-width: 430px) {\n    .add-episode-container {\n      font-size: 14px;\n      margin-left: 5%;\n      margin-right: 5%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
