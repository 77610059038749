// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* nav-icon-5 */
.nav-icon-5{
    width: 35px;
    height: 30px;
    margin: 10px 10px;
    position: relative;
    cursor: pointer;
    display: inline-block;
  }
  .nav-icon-5 span{
    background-color:#fff;
    position: absolute;
    border-radius: 2px;
    transition: .3s cubic-bezier(.8, .5, .2, 1.4);
    width:100%;
    height: 4px;
    transition-duration: 500ms
  }
  .nav-icon-5 span:nth-child(1){
    top:0px;
    left: 0px;
  }
  .nav-icon-5 span:nth-child(2){
    top:13px;
    left: 0px;
    opacity:1;
  }
  .nav-icon-5 span:nth-child(3){
    bottom:0px;
    left: 0px;
  }
  .nav-icon-5:not(.open):hover span:nth-child(1){
    transform: rotate(-3deg) scaleY(1.1);
  }
  .nav-icon-5:not(.open):hover span:nth-child(2){
    transform: rotate(3deg) scaleY(1.1);
  }
  .nav-icon-5:not(.open):hover span:nth-child(3){
    transform: rotate(-4deg) scaleY(1.1);
  }
  .nav-icon-5.open span:nth-child(1){
    transform: rotate(45deg);
    top: 13px;
  }
  .nav-icon-5.open span:nth-child(2){
    opacity:0;
  }
  .nav-icon-5.open span:nth-child(3){
    transform: rotate(-45deg);
    top: 13px;
  }`, "",{"version":3,"sources":["webpack://./src/components/NavBar/BurguerButton.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,qBAAqB;EACvB;EACA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,kBAAkB;IAClB,6CAA6C;IAC7C,UAAU;IACV,WAAW;IACX;EACF;EACA;IACE,OAAO;IACP,SAAS;EACX;EACA;IACE,QAAQ;IACR,SAAS;IACT,SAAS;EACX;EACA;IACE,UAAU;IACV,SAAS;EACX;EACA;IACE,oCAAoC;EACtC;EACA;IACE,mCAAmC;EACrC;EACA;IACE,oCAAoC;EACtC;EACA;IACE,wBAAwB;IACxB,SAAS;EACX;EACA;IACE,SAAS;EACX;EACA;IACE,yBAAyB;IACzB,SAAS;EACX","sourcesContent":["/* nav-icon-5 */\n.nav-icon-5{\n    width: 35px;\n    height: 30px;\n    margin: 10px 10px;\n    position: relative;\n    cursor: pointer;\n    display: inline-block;\n  }\n  .nav-icon-5 span{\n    background-color:#fff;\n    position: absolute;\n    border-radius: 2px;\n    transition: .3s cubic-bezier(.8, .5, .2, 1.4);\n    width:100%;\n    height: 4px;\n    transition-duration: 500ms\n  }\n  .nav-icon-5 span:nth-child(1){\n    top:0px;\n    left: 0px;\n  }\n  .nav-icon-5 span:nth-child(2){\n    top:13px;\n    left: 0px;\n    opacity:1;\n  }\n  .nav-icon-5 span:nth-child(3){\n    bottom:0px;\n    left: 0px;\n  }\n  .nav-icon-5:not(.open):hover span:nth-child(1){\n    transform: rotate(-3deg) scaleY(1.1);\n  }\n  .nav-icon-5:not(.open):hover span:nth-child(2){\n    transform: rotate(3deg) scaleY(1.1);\n  }\n  .nav-icon-5:not(.open):hover span:nth-child(3){\n    transform: rotate(-4deg) scaleY(1.1);\n  }\n  .nav-icon-5.open span:nth-child(1){\n    transform: rotate(45deg);\n    top: 13px;\n  }\n  .nav-icon-5.open span:nth-child(2){\n    opacity:0;\n  }\n  .nav-icon-5.open span:nth-child(3){\n    transform: rotate(-45deg);\n    top: 13px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
